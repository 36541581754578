import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from 'classnames';
import Intercom from 'react-intercom';
import * as SVGIcon from 'components/SVGIcon';
import DevTools from 'containers/DevTools';
import Hud from 'components/Hud';
import Menu from 'containers/parent/Menu';
import Modal from 'components/Modal';
import {toggleNav as toggleNavAction} from 'actions/navigation';
import {validateStatus as validateStatusAction} from 'actions/parent/signUp';
import {listUnreviewedBookings as listUnreviewedBookingsAction} from 'actions/parent/booking';
import {
  openModal as openModalAction,
  closeModal as closeModalAction
} from 'actions/modal';
import {
  showHud as showHudAction,
  completeHud as completeHudAction
} from 'actions/hud';
import {
  loadConstants as loadConstantsAction
} from 'actions/settings';
import {loadFavorites as loadFavoritesAction} from 'actions/parent/profile';

export class App extends React.Component {
  static propTypes = {
    conversationId: PropTypes.string,
    loading: PropTypes.object.isRequired,
    listUnreviewedBookings: PropTypes.func.isRequired,
    authToken: PropTypes.string,
    hud: PropTypes.object.isRequired,
    modal: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    completeHud: PropTypes.func.isRequired,
    showHud: PropTypes.func.isRequired,
    loadConstants: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    title: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    toggleNav: PropTypes.func.isRequired,
    validateStatus: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this.handleNavClick = this.onNavClick();
    this.buildIntercom = this.buildIntercom.bind(this);
  }

  componentDidMount () { // https://facebook.github.io/react/tips/initial-ajax.html
    console.log('Version', 'manual from e494605085e17a6c61cea36408e2d48913f715f2 ');
    if (this.props.authToken) {
      this.props.loadFavorites(this.props.authToken);
    }
  }

  /*
   * 1a) show and hide loading overlay
   * 2a) load profile and fetch constants again
   * 3a) validate status, redirect to signup flow if necessary
   * 4a) prompt user to review bookings, but it's dismissable and won't come back until refresh
   */
  componentWillReceiveProps (newProps) {
    this.handleHudToggle(this.props.loading, newProps.loading);
    if (!this.props.authToken && newProps.authToken) { // this case triggers when user has just logged in
      this.props.loadFavorites(newProps.authToken);
    } else if (newProps.user) {
      const {
        location: {pathname},
        user: {status}
      } = newProps;
      if (!this.props.user) {
        this.props.validateStatus(status, pathname);
        if (newProps.user.status.unreviewed_bookings && !this.props.user) {
          this.props.listUnreviewedBookings(newProps.authToken);
        }
      } else {
        const oldStatus = this.props.user.status;
        const result = Object.keys(oldStatus).reduce((p, c) => (p && (oldStatus[c] === status[c])), true);
        if (!result) {
          this.props.validateStatus(status, pathname);
        }
      }
    }
  }

  handleHudToggle (loading, nextLoading) {
    const isLoading = Object.keys(loading).reduce((p, c) => (p || loading[c]), false);
    const isNextLoading = Object.keys(nextLoading).reduce((p, c) => (p || nextLoading[c]), false);
    if (isLoading && !isNextLoading) {
      this.props.completeHud();
    } else if (!isLoading && isNextLoading) {
      this.props.showHud({dark: false, title: 'Loading'});
    }
  }

  buildMenu () {
    if (process.env.NODE_ENV === 'test') { // refactor, probably don't need this anymore now that we're not using redux-burger-menu and radium
      return null;
    }
    return (
      <Menu />
    );
  }

  buildDevTools () {
    if (process.env.NODE_ENV !== 'development' || !process.env.DEV_TOOLS) {
      return null;
    }
    return (
      <DevTools />
    );
  }

  buildTitle (title) {
    const Logo = SVGIcon.Logo;
    const LogoText = SVGIcon.LogoText;
    const className = classNames({
      'c-header__logo': true,
      'c-header__logo--has-title': !!title
    });
    return (
      <div>
        <div {...{className}}>
          <a
              className="u-padding-small"
              href="//usetrusted.com"
          >
            <Logo active />
            <LogoText />
          </a>
        </div>
        {title && (<h1 className="c-header__title">{title}</h1>)}
      </div>
    );
  }

  onNavClick () {
    return () => {
      this.props.toggleNav(!this.props.isOpen);
    };
  }

  buildMenuIcon () {
    const className = classNames({
      'c-burger-menu-icon': true,
      'c-burger-menu-icon--active': this.props.isOpen
    });
    return (
      <div
          {...{className}}
          onClick={this.handleNavClick}
      >
        <div className="c-burger-menu-icon__clicky-bit" />
      </div>
    );
  }

  buildIntercom (userData, conversationId) {
    let user = {
    };
    if (userData) {
      user = {
        user_id: userData.id, // eslint-disable-line camelcase
        email: userData.email,
        name: `${userData.first_name} ${userData.last_name}`
      };
    }
    return (
      <Intercom
          appID={process.env.INTERCOM_ID}
          conversation_id={conversationId}
          {...user}
      />
    );
  }

  render () {
    const {
      closeModal,
      hud,
      modal,
      user,
      conversationId
    } = this.props;
    return (
      <div>
        {this.buildMenuIcon()}
        {this.buildMenu()}
        <div className="o-wrapper">
          <div className="o-layout">
            <div className="o-layout__item u-1/1 u-center-text c-header">
              {this.buildTitle(this.props.title)}
            </div>
          </div>
        </div>
        <div className="c-divider" />
        <div className="o-wrapper">
          {this.props.children}
          {this.buildDevTools()}
        </div>
        {/* {this.buildIntercom(user, conversationId)} */}
        <Modal
            classes={modal.classes}
            closeModal={closeModal}
            component={modal.component}
            isOpened={modal.isModalOpen}
            options={modal.options}
            title={modal.title}
        />
        <Hud
            active={hud.isHudVisible}
            brand={hud.brand}
            complete={hud.complete}
            dark={hud.dark}
            title={hud.title}
        />
      </div>
    );
  }
}

function mapStateToProps (state) {
  const {
    navigation: {
      isOpen,
      title
    },
    hud,
    modal,
    session: {
      authToken,
      user
    },
    loading,
    search: {
      conversationId
    }
  } = state;
  return ({
    loading,
    authToken,
    hud,
    isOpen,
    modal,
    user,
    title,
    conversationId
  });
}

const mapDispatchToProps = {
  showHud: showHudAction,
  openModal: openModalAction,
  completeHud: completeHudAction,
  loadConstants: loadConstantsAction,
  closeModal: closeModalAction,
  toggleNav: toggleNavAction,
  validateStatus: validateStatusAction,
  listUnreviewedBookings: listUnreviewedBookingsAction,
  loadFavorites: loadFavoritesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
